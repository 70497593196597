import { createAction } from 'redux-action';
export const GET_PRDS = 'GET_PRDS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BANNER = 'GET_BANNER';
export const GET_BRANDS = 'GET_BRANDS';
export const SET_GHADENI_PRODUCTS = 'SET_GHADENI_PRODUCTS';
export const UPDATE_GHADENI_PRODUCT = 'UPDATE_GHADENI_PRODUCT';

export const GetPrds = createAction(GET_PRDS, (list) => {
    return list
});
export const GetCategories = createAction(GET_CATEGORIES, (list) => {
    return list
});
export const GetBanner = createAction(GET_BANNER, (list) => {
    return list
});
export const GetBrands = createAction(GET_BRANDS, (list) => {
    return list
});

const initial = { prds: [], categories: [] };

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_PRDS:
            return { ...state, prds: action.payload || [] }
        case GET_CATEGORIES:
            return { ...state, categories: action.payload || [] }
        case GET_BANNER:
            return { ...state, banner: action.payload || [] }
        case GET_BRANDS:
            return { ...state, brands: action.payload || [] }

        case SET_GHADENI_PRODUCTS:
            return {
                ...state, ghadeniPrds: action.payload || []
            }
        case UPDATE_GHADENI_PRODUCT:
            return {
                ...state, ghadeniPrds: state.ghadeniPrds.map(item => item.id === action.payload.id ? action.payload : item)
            }
        default:
            return state;
    }
};
export default ex