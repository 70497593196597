import { createAction } from 'redux-action';
export const GET_USER = 'GET_USER';
export const UPDATE_FAV_LIST = 'UPDATE_FAV_LIST';
export const FLUSH_USER = 'FLUSH_USER';

export const GetUser = createAction(GET_USER, (list) => {
    return list
});

export const FlushUser = createAction(FLUSH_USER, (list) => {
    return list
});

const initial = {};

let ex = (state = initial, action) => {
    switch (action.type) {
        case GET_USER:
            return action.payload ? { fav: state?.fav, ...action.payload, locations: action.payload.locations } : false
        case UPDATE_FAV_LIST:
            let id = action.payload.id;
            let favourit = state.favourit || [];
            if (favourit.includes(id)) {
                favourit = favourit.filter((item) => item !== id)
            }else{
                favourit.push(id)
            }
            return { ...state, favourit}

        case FLUSH_USER:
            return {}
        default:
            return state;
    }
};
export default ex