import React from 'react';
import { View, TouchableOpacity } from 'react-native'
import { mainColor, redColor } from '../../constants/color';
import { Dot, FilledDot, Globe } from '../../constants/imgs';
import Image from './customImg'
import { Text } from './custom'
import { useSelector } from 'react-redux';

const ListCard = ({ data, onPress, selector, selected }) => {
    const { locale } = useSelector((state) => state.locale);

    return (
        <TouchableOpacity style={{ marginVertical: 8 }} onPress={onPress}>
            <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingHorizontal: 16,
                backgroundColor: mainColor,
                paddingVertical: 24,
                borderRadius: 8,
                borderColor: '#2B2730'
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                }}>
                    {
                        data?.img ?
                            <Image
                                svg
                                width={18}
                                height={18}
                                uri={data.img}
                            /> : null
                    }

                    <Text style={{ paddingHorizontal: 8, fontFamily: 'semiBold' }}>{data?.title}</Text>
                </View>
                {
                    selector ?
                        <View>
                            <Image
                                svg
                                width={18}
                                height={18}
                                uri={selected ? FilledDot : Dot}
                                fill={redColor}
                            />
                        </View> : null

                }


            </View>
        </TouchableOpacity>

    );
};

export default ListCard;