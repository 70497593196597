import React, { useState, useEffect } from "react";
import {
  useStripe,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { Button, View } from "native-base";
import { firestore, functions } from "../../constants/firebase/config.web";
import { useDispatch, useSelector } from "react-redux";
import { SetAlertMessage } from "../../reducers/settings";
import { ConfirmOnlinePayment, Logger } from "../../helpers/index";
import { FlushOrder } from "../../reducers/order";
import { makeid } from "../../helpers/helpers";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import { REACT_APP_ORDERS_COLLECTION } from "../../constants/variables/index";

const PaymentRequestForm = ({ disabled, onSubmit, receipt }) => {
  const stripe = useStripe();
  const navigator = useNavigation();
  const [paymentRequest, setPaymentRequest] = useState(null);

  const { currency } = useSelector(state => state.settings)
  const order = useSelector(state => state.order);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { locale, strings } = useSelector(state => state.locale)
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!ready) {
        setReady(true)
      }

    }, 3000);
  }, [])

  useEffect(() => {
    const onPaymentMethod = async ({ paymentMethod, complete }) => {
      if (order?.location && receipt && user && order?.items) {
        const paymentType = 'wallet';
        let id = firestore().collection(REACT_APP_ORDERS_COLLECTION).doc().id;
        let selectdDate = order?.date
        let userAddress = JSON.parse(JSON.stringify(order?.location))
        userAddress.geometry = userAddress?.geometry?.location || '';
        delete userAddress?.params;
        delete userAddress?.deliveryTime;
        delete userAddress?.timings;
        let post = {
          id,
          lang: locale || 'en',
          orderNumber: makeid(6),
          created: Date.now(),
          address: userAddress,
          type: userAddress?.type || 'delivery',
          paymentType: paymentType,
          items: Object.values(order?.items || {}).map(rec => {
            let item = {};
            ["name", "price", "key", "sameDay", "SKU", "count", "selectedModifiers", "unit"].map(key => {
              if (rec[key]) {
                item[key] = rec[key]
              }
            })
            return item
          }),
          customer: {
            name: user?.name || "",
            uid: user?.uid || "",
            number: user?.phoneNumber || "",
          },
          receipt,
          status: 'new'
        }
        if (selectdDate) {
          post.date = {
            date: selectdDate?.date?.dateTimestamp,
            slot: {
              start: moment(selectdDate.slot.start).valueOf(),
              end: moment(selectdDate.slot.end).valueOf()
            }
          }
        }

        if (['wallet', 'online'].includes(paymentType)) {
          try {
            await firestore().collection('authorize').doc(id).set(post)
            let CreatePaymentIntent = await functions().httpsCallable('CreatePaymentIntent')({
              paymentMethod: paymentMethod.id,
              customer_id: user?.stripe_id,
              amount: Number(receipt.total) * 100,
              customerData: user,
              discount: receipt?.code || false,
              currency: currency,
              order_id: id
            });
            console.log("CreatePaymentIntent", CreatePaymentIntent?.data);

            if (CreatePaymentIntent?.data?.error) {
              dispatch(SetAlertMessage({
                title: strings.alert,
                msg: CreatePaymentIntent?.data?.error?.message,
                confirmText: 'Got it',
                alert: true

              }))
              return
            } else {
              const { intent: { status, client_secret, id: payment_id } } = CreatePaymentIntent.data || { intent: {} }
              console.log("CreatePaymentIntent,", CreatePaymentIntent.data, status, client_secret, payment_id);
              if (["requires_confirmation", "requires_action", 'requires_payment_method'].includes(status)) {
                const { error } = await dispatch(ConfirmOnlinePayment({ paymentIntent: CreatePaymentIntent?.data?.intent, selectedCard: paymentMethod.id, paymentType, stripe }));
                if (error) {
                  dispatch(SetAlertMessage({
                    title: strings.alert,
                    msg: error?.localizedMessage || error?.message,
                    confirmText: 'Got it',
                    alert: true

                  }))
                  return
                }
              }
              post.payment_id = payment_id
              post.client_secret = client_secret
            }
          } catch (error) {
            console.log("errorerrorerrorerror", error);
            dispatch(SetAlertMessage({
              title: strings.alert,
              msg: error?.localizedMessage || error?.message,
              confirmText: 'Got it',
              alert: true
            }))
            return
          }
        }
        firestore().collection(REACT_APP_ORDERS_COLLECTION).doc(id).set(post).then(() => {
          navigator.replace('Status Page', { data: post })
          dispatch(FlushOrder())
          return complete('success');
        }).catch(error => {
          Logger(error)
          dispatch(SetAlertMessage({
            title: strings.alert,
            msg: 'Somthing went wrong, Please try again or contact us',
            confirmText: 'Got it',
            alert: true
          }))
        })
      }
      // return complete('fail');


    }
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethod)

    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', onPaymentMethod)
      }
    }
  }, [paymentRequest])

  useEffect(() => {
    if (stripe && receipt?.total) {
      console.log("receipt?.total", receipt?.total);

      if (paymentRequest) {
        paymentRequest.update({
          country: "AE",
          currency: "aed",
          total: {
            label: "Tayba",
            amount: Number((receipt?.total * 100).toFixed(2)),
          },
        })
        paymentRequest.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(paymentRequest);
          }
        });
      } else {
        const pr = stripe.paymentRequest({
          country: "AE",
          currency: "aed",
          total: {
            label: "Tayba",
            amount: Number((receipt?.total * 100).toFixed(2)),
          },
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }

    }
  }, [stripe, receipt?.total])

  if (!paymentRequest) {
    return <Button isLoading={!ready} isLoadingText=' ' disabled={true} minH={50} borderRadius={8} backgroundColor='gray.600'>Not Activated</Button>;
  }

  return (
    <View style={{
      opacity: disabled ? 0.3 : 1
    }}>
      <PaymentRequestButtonElement

        className="PaymentRequestButton"
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              theme: "dark",
              height: "48px",
              type: 'order',
              borderRadius: 16,
            }
          }
        }}
        onReady={() => {
          setReady(true)
          // console.log("PaymentRequestButton [ready]");
        }}
        onClick={event => {
          if (disabled) {
            console.log("disableddisabled", disabled);
            event.preventDefault()
            return
          }
          console.log("PaymentRequestButton [click]", event);
        }}
      // onBlur={() => {
      //   console.log("PaymentRequestButton [blur]");
      // }}
      // onFocus={() => {
      //   console.log("PaymentRequestButton [focus]");
      // }}
      />
    </View>




  );
};

export default PaymentRequestForm;
