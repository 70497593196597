import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
const Navigation = () => {
    const NavigationStack = createStackNavigator();
    return (
        <NavigationStack.Navigator
            initialRouteName='Ghadeni'
        >


        </NavigationStack.Navigator>

    );
};

export default Navigation;