import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, TextInput, Platform, KeyboardAvoidingView, View, Keyboard } from 'react-native';
import { Text } from "../shared/custom";
import { backgroundColor, colorTextGrey, greenColor, mainColor, redColor } from '../../constants/color';
import LocationCard from '../shared/LocationCard';
import Image from '../shared/customImg'
import { FilledDot, Watch, Trash, collapseSVG, collapseSVGUP, Dot, FreeDeliverySVG, Visa, MasterCard } from '../../constants/imgs';
import ActionBtn from '../shared/ActionBtn';
import SelectTime from './SelectTime';
import CartCard from '../shared/CartCard';
import { useNavigation } from '@react-navigation/core';
import { useDispatch, useSelector } from 'react-redux';
import { calcTotalCost, makeid, translateSelectedSlot, applyPromo, CheckStoreClosed, validatePromo, PaymentMethods, VerifyProdsUpdated } from '../../helpers/helpers';
import { setOrderDiscount, FlushOrder, SetOrderDeliveryDate, UpdateOrderItems } from '../../reducers/order';
import { firestore } from '../../constants/firebase/config'
import moment from 'moment';
import CustomImage from '../shared/customImg'
import Alert from '../shared/Alert';
import { useHeaderHeight } from '@react-navigation/elements';
import OrderOption from '../home/OrderOption';
import { SetAlertMessage } from '../../reducers/settings';
import { formatTime } from '../shared/ClosedBanner';
import { Logger } from '../../helpers/index';
import { Pressable, ScrollView, TextArea } from 'native-base';
import CardOptionComp from '../shared/CardOptionComp';
import { functions } from '../../constants/firebase/config';
import { ConfirmOnlinePayment } from '../../helpers/index';
import PaymentRequestForm from '../Payment/PaymentBtn';
import { Stripe } from '../../helpers/index';
import { REACT_APP_ORDERS_COLLECTION } from '../../constants/variables/index';
import { LogEvent } from '../../helpers/index';
// import { isPlatformPaySupported } from '../../helpers/index';

const Cart = () => {
    const { locale, strings } = useSelector((state) => state.locale);
    const { allLocations, pickupLocations, currency, country, onilnePayment: { country: supportedCountries = [] } } = useSelector(state => state.settings);
    const { prds } = useSelector(state => state.helpers)
    const orders = useSelector(state => state.orders)
    const stripe = Stripe();
    const { activeOffer: offer } = useSelector(state => state.offer)
    const order = useSelector(state => state.order)
    const user = useSelector(state => state.user)
    const [code, setCode] = useState(false);
    const dispatch = useDispatch();
    const [state, setState] = useState({})
    const [collapse, setCollapse] = useState(true)
    const [paymentType, setPaymentType] = useState('cash')
    const [showModal, setModal] = useState(false)
    const navigator = useNavigation()
    const [orderItems, setItems] = useState({})
    const [selectedLocation, setSelectedLocation] = useState(false)
    const [alert, setAlert] = useState(false);
    const [disabled, toggleDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectLocation, showSelectLocation] = useState(false)
    let receipt = calcTotalCost(order?.items, offer, selectedLocation?.fees, selectedLocation?.freeDelivery)

    const [dates, setDates] = useState([])
    const [PaymentMethodes, setPaymentMethodes] = useState([])

    useEffect(() => {
        // (async (params) => {
        if (supportedCountries?.includes(country)) {
            // let walletSupported = await isPlatformPaySupported({ receipt, stripe });
            // console.log("walletSupported",walletSupported);

            // if (walletSupported) {
            setPaymentMethodes(PaymentMethods)
            if (Platform.OS === 'ios') {
                setPaymentType('wallet')

            }
            // } else {
            //     let filtered = PaymentMethods.filter(r => r.id !== 'applepay')
            //     setPaymentMethodes(filtered)
            //     setPaymentType('cash')
            // }
        } else {
            setPaymentMethodes([
                { id: 'cash', delivery: 'Cash On Delivery', pickup: 'Cash On Pickup' },
                { id: 'card', delivery: 'Card On Delivery (POS Machine)', pickup: 'Card On Pickup (POS Machine)' }
            ])
            setPaymentType('cash')

        }

        // })()

    }, [supportedCountries, receipt?.total])
    useEffect(() => {
        if (offer?.code) {
            (async () => {
                try {
                    let snap = await firestore().collection('offers').where('code', '==', offer?.code).limit(1).get();
                    if (snap.docs.length) {
                        let data = snap.docs[0]?.data();
                        let activeOrders = Object.values(orders || {})?.filter(r => r.receipt.code === offer?.code);
                        let isNewUser = Object.values(orders || {})?.length === 0;
                        const { error } = validatePromo({ data: { ...data, id: snap.docs[0]?.id }, activeOrders: activeOrders?.length, isNewUser });
                        if (!error) {
                            return dispatch(setOrderDiscount(offer))
                        }
                    }
                    dispatch({ type: 'SET_ACTIVE_OFFER', payload: false })
                    dispatch(setOrderDiscount(false))
                } catch (error) { }

            })()
        } else {
            dispatch(setOrderDiscount(false))
        }
    }, [offer, orders])


    useEffect(() => {
        let { items } = order || {}
        switch (order?.location?.type) {
            case 'delivery':
                switch (receipt?.orderType) {
                    case 'mix': case 'sameDay':
                        setItems({ sameDay: Object.values(items || {}) })
                        toggleDisabled(!!dispatch(CheckStoreClosed()))
                        break;
                    default:
                        toggleDisabled(false)
                        let sameDay = items && Object.values(items || {})?.filter(r => r.sameDay)
                        let scdualed = items && Object.values(items || {})?.filter(r => !r.sameDay)
                        setItems({ sameDay, scdualed })
                        break;
                }
                break;
            default:
                setItems({ sameDay: Object.values(items || {}) })
                break;
        }


    }, [order?.items, offer, order?.location?.type, allLocations])



    useEffect(() => {
        if (!user?.locations) {
            return navigator.navigate('Choose your location')
        }
    }, [user?.locations])
    const headerHeight = useHeaderHeight()
    useEffect(() => {
        (async () => {
            if (order?.location) {
                let locations = order?.location?.type === 'pickup' ? pickupLocations : allLocations;
                if (locations?.[order?.location?.locationId || order?.location?.id]) {
                    setSelectedLocation(locations[order?.location?.locationId])
                } else {
                    setSelectedLocation(false)
                    setAlert({
                        title: strings.alert,
                        msg: strings?.['Sorry, Currently we are not serving this area!'],
                        confirmText: 'Change address',
                        onCancel: () => {
                            navigator.goBack()
                            setAlert(false)
                        },
                        alert: true
                    })
                }
            }
        })()
    }, [order?.location, allLocations, pickupLocations])

    const ItemsUpdated = useMemo(() => {
        if (loading) {
            return []
        }
        try {
            return VerifyProdsUpdated(prds, order);
        } catch (error) {
            console.log("errorerrorerrorerror", error);
            return [];
        }
    }, [prds, order, loading]);


    useEffect(() => {
        try {
            if (ItemsUpdated?.length) {
                let optimizedItems = JSON.parse(JSON.stringify(order.items))
                ItemsUpdated.map(rec => {
                    delete optimizedItems[rec.key]
                    return false
                })
                dispatch(SetAlertMessage({
                    type: 'alert',
                    title: 'Sorry!',
                    msg: strings?.['Due to updates during checkout, some items have been removed from your cart. Please review your cart before proceeding!'],
                    alert: true,
                    onCancel: () => {
                        dispatch(UpdateOrderItems(optimizedItems))
                        dispatch(SetAlertMessage(false))
                    }
                }))

            }
        } catch (error) {
            console.log("errorerrorerrorerror", error);
        }

    }, [ItemsUpdated])


    useEffect(() => {
        let weekDays = [{ Key: 'Sunday', Label: 'Sun' },
        { Key: 'Monday', Label: 'Mon' },
        { Key: 'Tuesday', Label: 'Tue' },
        { Key: 'Wednesday', Label: 'Wed' },
        { Key: 'Thursday', Label: 'Thu' },
        { Key: 'Friday', Label: 'Fri' },
        { Key: 'Saturday', Label: 'Sat' }]
        let dates = []
        let step = moment()
        if (selectedLocation?.timings) {
            for (var i = 0; i < 14; i++) {
                let weekDay = weekDays[step.day()]
                let dayTimming = selectedLocation?.timings[weekDay.Key];
                let { isOpen } = dayTimming;
                let buffer = selectedLocation?.buffer || 60;
                let today = moment().date() === step.date();
                let slots = dayTimming.timings?.reduce((val, rec) => {
                    let start = moment(rec.startTime)
                    let end = moment(rec.endTime)
                    if (today) {
                        let nowTime = moment(`1955-01-05T${moment().add(buffer, 'minutes').format('HH:mm:ss')}`);
                        if (nowTime.isBefore(start)) {
                            val.push({
                                start, end
                            })
                        }
                    } else {
                        val.push({
                            start, end
                        })
                    }
                    return val
                }, [])

                dates.push({
                    id: i,
                    date: step.format('DD'),
                    weekDay: weekDay.Label,
                    dateTimestamp: step.valueOf(),
                    slots: isOpen ? slots : []
                })

                step.add(1, 'day')
            }
        }
        setDates(dates)
        dispatch(SetOrderDeliveryDate(false))

    }, [selectedLocation?.timings]);

    useEffect(() => {
        if (!order?.date?.date && dates?.length && orderItems?.scdualed?.length) {
            dispatch(SetOrderDeliveryDate(getFirstAvailableDayAndSlot(dates)))
        }
    }, [dates, order?.date, orderItems?.scdualed?.length])
    const getFirstAvailableDayAndSlot = (dates) => {
        for (let i = 0; i < dates?.length; i++) {
            const date = dates?.[i];
            if (date?.slots?.length > 0) {
                const slot = date?.slots?.[0];
                return { date, slot };
            }
        }

        return false
    };
    const submitOrder = async () => {
        let pressed = false;
        if (disabled) {
            let counter = dispatch(CheckStoreClosed())
            dispatch(SetAlertMessage({
                title: 'Sorry!',
                msg: `We cannot accept orders at the moment, you can place your order after ${formatTime(counter)}`,
                confirmText: 'Got it',
                alert: true,
                onCancel: () => {
                    dispatch(SetAlertMessage(false))
                }
            }))
            return

        }

        setAlert({
            title: strings?.['Order Confirmation'],
            msg: strings?.['Are you sure you want to place this order? We’re excited to get it ready for you!'],
            confirmText: strings?.['Place Order'],
            onCancel: () => {
                setAlert(false)
            },
            onConfirm: async () => {
                if (order?.location && receipt && user && order?.items && !pressed) {
                    pressed = true
                    setLoading(true);
                    setAlert(false);
                    try {
                        dispatch(LogEvent({
                            event: 'checkout', data: {
                                receipt,
                                order: order,
                            }
                        }))
                    } catch (error) {
                        console.log("errorerrorerrorerror", error);

                    }
                    let id = firestore().collection(REACT_APP_ORDERS_COLLECTION).doc().id;
                    let selectdDate = order?.date
                    let userAddress = JSON.parse(JSON.stringify(order?.location))
                    userAddress.geometry = userAddress?.geometry?.location || '';
                    delete userAddress?.params;
                    delete userAddress?.deliveryTime;
                    delete userAddress?.timings;
                    let post = {
                        platform: Platform.OS,
                        id,
                        lang: locale || 'en',
                        branch: selectedLocation?.branch || false,
                        orderNumber: makeid(6),
                        created: Date.now(),
                        address: userAddress,
                        type: userAddress?.type || 'delivery',
                        paymentType: paymentType,
                        items: Object.values(order?.items || {}).map(rec => {
                            let item = {};
                            ["name", "price", "key", "sameDay", "SKU", "count", "selectedModifiers", "unit"].map(key => {
                                if (rec[key]) {
                                    item[key] = rec[key]
                                }
                            })
                            return item
                        }),
                        customer: {
                            name: user?.name || "",
                            uid: user?.uid || "",
                            number: user?.phoneNumber || "",
                        },
                        receipt,
                        status: 'new'
                    }
                    if (order?.note) {
                        post.note = order?.note
                    }
                    if (selectdDate) {
                        post.date = {
                            date: selectdDate?.date?.dateTimestamp,
                            slot: {
                                start: moment(selectdDate.slot.start).valueOf(),
                                end: moment(selectdDate.slot.end).valueOf()
                            }
                        }
                    }

                    if (['wallet', 'online'].includes(paymentType)) {
                        try {
                            dispatch({ type: 'SET_LOADING', payload: true })
                            await firestore().collection('authorize').doc(id).set(post)
                            let CreatePaymentIntent = await functions().httpsCallable('CreatePaymentIntent')({
                                paymentMethod: paymentType === 'online' ? state.selectedCard : false,
                                customer_id: user?.stripe_id,
                                amount: Number(receipt.total) * 100,
                                customerData: user,
                                discount: receipt?.code || false,
                                currency: currency,
                                order_id: id
                            });
                            if (CreatePaymentIntent?.data?.error) {
                                dispatch({ type: 'SET_LOADING', payload: false })
                                setLoading(false)
                                setAlert({
                                    title: strings.alert,
                                    msg: CreatePaymentIntent?.data?.message || CreatePaymentIntent?.data?.error?.message,
                                    confirmText: 'Got it',
                                    onCancel: () => {
                                        setAlert(false)
                                    },
                                    alert: true
                                })
                                return
                            } else {
                                const { intent: { status, client_secret, id: payment_id } } = CreatePaymentIntent.data || { intent: {} }
                                console.log("CreatePaymentIntent,", JSON.stringify(CreatePaymentIntent.data));
                                if (["requires_confirmation", "requires_action", 'requires_payment_method'].includes(status)) {
                                    const { error } = await dispatch(ConfirmOnlinePayment({ paymentIntent: CreatePaymentIntent?.data?.intent, selectedCard: state.selectedCard, paymentType, stripe }));
                                    if (error) {
                                        console.log("errorerrorerrorerror", error);
                                        setLoading(false)
                                        dispatch({ type: 'SET_LOADING', payload: false })
                                        setAlert({
                                            title: strings.alert,
                                            msg: error?.localizedMessage || error?.message,
                                            confirmText: 'Got it',
                                            onCancel: () => {
                                                setAlert(false)
                                            },
                                            alert: true
                                        })
                                        return
                                    }
                                }
                                post.payment_id = payment_id
                                post.client_secret = client_secret
                            }
                        } catch (error) {
                            console.log("errorerrorerrorerror", error);
                            setAlert({
                                title: strings.alert,
                                msg: error?.localizedMessage || error?.message,
                                confirmText: 'Got it',
                                onCancel: () => {
                                    setAlert(false)
                                },
                                alert: true
                            })
                            return
                        }
                        setLoading(false)
                    }
                    try {
                        await firestore().collection(REACT_APP_ORDERS_COLLECTION).doc(id).set(post);
                        navigator.replace('Status Page', { data: post });
                        try {
                            dispatch(LogEvent({
                                event: 'purchase', data: {
                                    receipt,
                                    order: order,
                                }
                            }))
                        } catch (error) {
                            console.log("errorerrorerrorerror", error);
                        }
                        dispatch(FlushOrder())
                    } catch (error) {
                        dispatch({ type: 'SET_LOADING', payload: false })
                        setLoading(false)
                        Logger(error)
                        setAlert({
                            title: strings.alert,
                            msg: 'Somthing went wrong, Please try again or contact us',
                            confirmText: 'Got it',
                            onCancel: () => {
                                setAlert(false)
                            },
                            alert: true
                        })
                    }
                }
                dispatch({ type: 'SET_LOADING', payload: false })
                setAlert(false)
            }
        })
        return

    }
    return (
        <View style={{ flex: 1, backgroundColor: backgroundColor }}>
            {
                alert && <Alert {...alert} />
            }
            {
                selectLocation && <OrderOption user={user} dismiss={() => showSelectLocation(false)} />
            }

            <KeyboardAvoidingView style={{ flex: 1 }} keyboardVerticalOffset={headerHeight} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
                {receipt?.freeDeliveryActive && receipt?.deliveryFees ? <View style={{
                    paddingHorizontal: 16,
                    paddingVertical: 12,
                    backgroundColor: 'rgba(255, 137, 0, 0.05)',
                    borderColor: 'rgba(182,147,41,.1)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <Image
                        fill='#DE8329'
                        svg
                        width={25}
                        height={25}
                        uri={FreeDeliverySVG}
                    />
                    <View style={{ marginHorizontal: 15 }}>
                        <Text style={{
                            color: '#000',
                            textAlign: 'left',
                            fontSize: 12,
                        }}>{strings.needFreeShipping}</Text>

                        <Text style={{
                            color: '#000',
                            textAlign: 'left',
                            fontSize: 14,
                            fontFamily: 'bold',
                        }}>
                            {strings.formatString(strings.forFreeShipping, {
                                amount: (selectedLocation?.freeDelivery - receipt.subFreeDelivery).toFixed(2),
                                currency: strings[currency]
                            })}
                        </Text>

                    </View>
                </View> : <></>}
                <ScrollView flex={1}
                    showsVerticalScrollIndicator={false}
                    style={{
                        maxHeight: Platform.OS === 'web' ? `calc(100vh - ${receipt?.freeDeliveryActive && receipt?.deliveryFees ? '62px' : '-18px'} - 187px - ${headerHeight}px)` : 'auto',
                    }} showsHorizontalScrollIndicator={false} contentContainerStyle={{
                        paddingBottom: 100
                    }} >

                    <View style={{ paddingVertical: 32, paddingHorizontal: 16, width: '100%' }}>
                        <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold', paddingBottom: 16 }}>{order?.location?.type === 'pickup' ? strings.pickupFrom : strings.deliveringTo}</Text>
                        <LocationCard data={order?.location} user={user} select />
                    </View>
                    {
                        orderItems?.sameDay?.length ? <View style={{ paddingHorizontal: 16, width: '100%' }}>
                            <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'semiBold' }}>{order?.location?.type === 'delivery' ? strings.fastDelivery : strings?.Items}</Text>
                            {
                                order?.location?.type === 'delivery' ?
                                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16, paddingTop: 8 }}>
                                        <Image
                                            fill={'#DE8329'}
                                            svg
                                            uri={Watch}
                                            width={10}
                                            height={10}
                                        />
                                        <Text style={{ color: '#DE8329', paddingHorizontal: 4, fontSize: 12 }}>{strings.estDelivery}</Text>
                                        <Text style={{ color: '#DE8329', fontSize: 12, fontFamily: 'bold' }}>{(selectedLocation?.deliveryTime?.value || '') + " " + (selectedLocation?.deliveryTime?.label || '')}</Text>
                                    </View>
                                    :
                                    <View style={{ padding: 4 }} />
                            }

                            {
                                orderItems?.sameDay?.map(rec =>
                                    <View key={rec.id} style={{ paddingBottom: 16 }}>
                                        <CartCard data={rec} />
                                    </View>

                                )
                            }
                        </View>
                            :
                            <View />
                    }
                    {
                        orderItems?.scdualed?.length ? <View style={{ paddingHorizontal: 16, paddingTop: 16, width: '100%' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Text style={{ textAlign: 'left', fontSize: 18, fontFamily: 'bold' }}>{strings?.["Scheduled Delivery"]}</Text>
                                <TouchableOpacity onPress={() => setModal(true)}>
                                    <Text style={{ color: redColor, textDecorationLine: 'underline', fontSize: 12, fontFamily: 'semiBold' }}>{strings.scheduleDelivery}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 16, paddingTop: 8 }}>
                                <Image
                                    fill={'#DE8329'}
                                    svg
                                    uri={Watch}
                                    width={10}
                                    height={10}
                                />
                                <Text style={{ color: '#DE8329', paddingHorizontal: 4, fontSize: 12 }}>{strings.estDelivery}</Text>
                                <Text style={{ color: '#DE8329', fontSize: 12, fontFamily: 'semiBold' }}>{translateSelectedSlot(order?.date) || ""}</Text>
                            </View>
                            {
                                orderItems?.scdualed?.map(rec =>
                                    <View key={rec.id} style={{ paddingBottom: 16 }}>
                                        <CartCard data={rec} />
                                    </View>

                                )
                            }
                        </View>
                            :
                            <View />
                    }


                    <View style={{ paddingHorizontal: 16, paddingTop: 16, width: '100%' }}>
                        <Text style={{ textAlign: 'left', fontSize: 18, paddingBottom: 8, fontFamily: 'semiBold' }}>{strings?.paymentMethod}</Text>
                        {
                            PaymentMethodes?.map(rec =>
                                <View key={rec.id}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setState({ ...state, selectedCard: false })
                                            setPaymentType(rec.id)
                                        }}
                                        key={rec.id}
                                        style={{ flexDirection: 'row', paddingVertical: 8, alignItems: 'center' }}>
                                        <Image
                                            svg
                                            width={15}
                                            height={15}
                                            uri={paymentType === rec.id ? FilledDot : Dot}
                                            fill={redColor}
                                        />
                                        <Text style={{
                                            fontFamily: 'semiBold',
                                            fontSize: 12,
                                            paddingHorizontal: 6
                                        }}>
                                            {strings?.[order?.location?.type === 'pickup' ? rec.pickup : rec.delivery] || (order?.location?.type === 'pickup' ? rec.pickup : rec.delivery)}
                                        </Text>

                                    </TouchableOpacity>
                                    {
                                        (rec.id === 'online' && 'online' === paymentType) && <View style={{ paddingVertical: 8 }}>
                                            <View>
                                                {
                                                    user?.paymentMethods?.map(card => <Pressable
                                                        paddingBottom={4}
                                                        key={card.id}

                                                        onPress={() => setState({ ...state, selectedCard: card.id })}>
                                                        <CardOptionComp
                                                            card={true}
                                                            backgroundColor='#ffffff'
                                                            key={card.id}
                                                            data={{ title: `XXXX ${card?.last4}`, image: card?.brand === 'visa' ? Visa : MasterCard }}
                                                            selected={state?.selectedCard === card.id} />
                                                    </Pressable>)

                                                }
                                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                                                    <Pressable style={{ marginBottom: 16, paddingHorizontal: 16 }} onPress={() => navigator.navigate('AddCard')}>
                                                        <Text style={{ color: redColor }}>{strings?.['+ Add new card']}</Text>
                                                    </Pressable>
                                                </View>
                                            </View>
                                        </View>

                                    }
                                </View>
                            )
                        }

                    </View>

                    {
                        order?.discount ?
                            <View style={{
                                marginVertical: 24,
                                marginHorizontal: 16,
                                paddingHorizontal: 14,
                                paddingVertical: 10,
                                borderRadius: 8,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                height: 48,
                                backgroundColor: mainColor
                            }}>
                                <Text style={{ fontSize: 18 }}>{order?.discount?.code}</Text>
                                <TouchableOpacity onPress={() => {
                                    dispatch(setOrderDiscount(false))
                                    dispatch({ type: 'SET_ACTIVE_OFFER', payload: false })
                                }}>
                                    <CustomImage
                                        svg
                                        width={20}
                                        height={20}
                                        uri={Trash}
                                        fill={redColor}

                                    />
                                </TouchableOpacity>
                            </View>
                            :


                            <View style={{
                                height: 48,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginHorizontal: 16,
                                marginVertical: 30,
                                flexDirection: 'row',
                                overflow: 'hidden',
                                backgroundColor: '#fff',
                                borderRadius: 8,

                            }}>
                                <TextInput

                                    onChangeText={(text) => setCode(text)}
                                    placeholder={strings.enterPromocode}
                                    selectionColor="#FF0000"
                                    style={{
                                        outline: 'none', height: 60, paddingHorizontal: 8, flex: 2, textAlign: locale === 'en' ? 'left' : 'right'
                                    }}
                                />
                                <TouchableOpacity
                                    onPress={async () => {
                                        let res = await dispatch(applyPromo(code));
                                        if (res?.error) {
                                            setAlert({
                                                title: strings.alert,
                                                msg: res?.error,
                                                confirmText: strings.completeProfile,
                                                onCancel: () => {
                                                    if (alert?.navigate) {
                                                        showSelectLocation(true)
                                                    }
                                                    setAlert(false)
                                                },
                                                alert: true
                                            })
                                        } else if (res) {
                                            dispatch({ type: 'SET_ACTIVE_OFFER', payload: res })
                                            dispatch(setOrderDiscount(res))
                                        }
                                    }}
                                    style={{ height: '100%', borderLeftColor: '#DDE9F1', borderLeftWidth: 1, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <Text style={{ fontSize: 12, color: redColor, fontFamily: 'semiBold' }}>{strings.applyPromo}</Text>
                                </TouchableOpacity>
                            </View>

                    }


                    <View style={{ paddingHorizontal: 16, paddingTop: 16, width: '100%' }}>
                        <Text style={{ textAlign: 'left', fontSize: 18, paddingBottom: 8, fontFamily: 'semiBold' }}>{strings?.specialNotes}</Text>
                        <View style={{
                            paddingTop: 8,
                        }}>
                            <TextArea
                                borderColor={'#cacaca30'}
                                selectionColor={'#cacaca30'}
                                returnKeyType='done'
                                placeholder={strings?.['Add special notes to your order']}
                                _focus={{
                                    borderColor: '#cacaca30',
                                    borderRadius: 8,
                                }}
                                onEndEditing={(e) => {
                                    dispatch({ type: 'SET_ORDER_NOTE', payload: e.nativeEvent.text })
                                    console.log("e.nativeEvent.text", e.nativeEvent.text);
                                }}
                                //dismss keyboard on submit
                                onKeyPress={({ nativeEvent }) => {
                                    if (nativeEvent.key === 'Enter') {
                                        Keyboard.dismiss()
                                    }
                                }}

                                style={{
                                    textAlign: locale === 'ae' ? 'right' : 'left',
                                    borderRadius: 8,
                                    backgroundColor: '#fff',
                                    borderColor: '#cacaca30',
                                    padding: 8,
                                }} />
                        </View>

                    </View>

                </ScrollView>
            </KeyboardAvoidingView>
            <View style={{
                // flex: 0.25,
                backgroundColor: backgroundColor,
                paddingVertical: 16,
                shadowColor: '#00000030',
                shadowOffset: {
                    width: 0,
                    height: 3
                },
                elevation: 20,
                shadowRadius: 5,
                shadowOpacity: 1.0,
                position: Platform.OS === 'web' ? 'fixed' : 'relative',
                bottom: 0,
                width: '100%',
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: '',
                // justifyContent: 'space-between'
            }}>
                <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8 }}>
                    <TouchableOpacity onPress={() => {
                        setCollapse(!collapse)
                    }}>
                        <CustomImage
                            svg
                            width={25}
                            height={25}
                            uri={collapse ? collapseSVGUP : collapseSVG}
                            fill={redColor}

                        />
                    </TouchableOpacity>
                </View>
                {!collapse ? <View style={{ paddingHorizontal: 16 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: 'medium' }}>{strings.subTotal}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {Number(receipt.sub).toFixed(2)}</Text>
                    </View>
                    {/* <View style={{ flexDirection: 'row', paddingVertical: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: 'semiBold' }}>{strings.tax}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {Number(receipt.tax).toFixed(2)}</Text>
                    </View> */}
                    {receipt?.deliveryFees && receipt.sub !== "0.00" ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12, fontFamily: 'medium', }}>{strings.Delivery}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'semiBold' }}>{strings[currency]} {Number(receipt?.deliveryFees).toFixed(2)}</Text>
                    </View> :
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: 12, fontFamily: 'medium' }}>{strings.Delivery}</Text>
                            <Text style={{ fontSize: 13, fontFamily: 'semiBold', color: greenColor }}>{strings.free}</Text>
                        </View>}
                    {
                        receipt.deductedValue &&
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text style={{ fontSize: 12, fontFamily: 'medium', color: greenColor }}>{strings.discount} {`(${receipt.code})`}</Text>
                            <Text style={{ fontSize: 13, fontFamily: 'semiBold', color: greenColor }}>{strings[currency]} -{Number(receipt.deductedValue).toFixed(2)}</Text>
                        </View>
                    }
                    <View style={{ width: '100%', height: 1, backgroundColor: 'lightgray', marginVertical: 8 }} />
                    <View style={{
                        flexDirection: 'row',
                        borderTopWidth: 1,
                        borderTopColor: "#F5F5F5",
                        paddingTop: 8,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 30
                    }}>
                        {/* <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{strings.total}</Text> */}
                        <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{strings.total} <Text style={{ fontSize: 10, color: colorTextGrey, fontFamily: 'light' }}>{strings.allPricesIncludeVAT}</Text></Text>

                        <Text >{receipt.sub !== "0.00" ? <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.total}</Text> : '--'}</Text>
                    </View>
                </View> : <View style={{ paddingHorizontal: 16 }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 30
                    }}>
                        <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{strings.total} <Text style={{ fontSize: 10, color: colorTextGrey, fontFamily: 'light' }}>{strings.allPricesIncludeVAT}</Text></Text>
                        {/* <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.total}</Text> */}
                        <Text >{receipt.sub !== "0.00" ? <Text style={{ fontSize: 14, fontFamily: 'semiBold' }}>{strings[currency]} {receipt.total}</Text> : '--'}</Text>

                    </View>
                </View>}
                {
                    paymentType === 'wallet' ?
                        <View style={{ paddingHorizontal: 16 }}>
                            <PaymentRequestForm
                                disabled={(selectedLocation?.minOrder && Number(receipt.total) < Number(selectedLocation?.minOrder)) || receipt.sub === "0.00" || (paymentType === 'online' && !state.selectedCard) || loading}
                                receipt={receipt}
                                onSubmit={async () => {
                                    await submitOrder()
                                }} />
                        </View>
                        :
                        <ActionBtn
                            fromCart={true}
                            loading={loading}
                            title={strings.completeOrder}
                            disabled={(selectedLocation?.minOrder && Number(receipt.total) < Number(selectedLocation?.minOrder)) || receipt.sub === "0.00" || (paymentType === 'online' && !state.selectedCard)}
                            onPress={async () => {
                                await submitOrder()
                            }} />
                }
                {selectedLocation?.minOrder && Number(receipt.total) < Number(selectedLocation?.minOrder) ? <View style={{ paddingHorizontal: 16, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ color: '#DE8329' }}>{strings.minOrderIs}</Text>
                    <Text style={{ color: '#DE8329', paddingHorizontal: 4, fontFamily: 'semiBold' }}>{selectedLocation?.minOrder + " " + strings[currency]} </Text>
                    <Text style={{ color: '#DE8329' }}>{strings.toThisArea}</Text>
                </View> : <></>}
            </View>

            {
                showModal && <SelectTime data={dates} dismiss={() => setModal(false)} />
            }
        </View >

    );
};

export default Cart;