import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, SafeAreaView, Platform, ScrollView, Linking } from 'react-native';
import { backgroundColor, mainColor, secColor } from '../../constants/color';
import { ArrowRight, heart, Profile, Recent, location, Globe, Chat, Logout, BackSVG, SAFlag, AEFlag, PaymentCardsSVG, SheildSVG } from '../../constants/imgs';
import Image from '../shared/customImg'
import { useNavigation } from '@react-navigation/native';
import Alert from '../shared/Alert';
import { Text } from '../shared/custom'
import { connect, useSelector } from 'react-redux';
import { logout } from '../../helpers/index';
const UserProfile = ({ user }) => {
    const { locale, strings } = useSelector((state) => state.locale);
    const { country } = useSelector(state => state.settings);

    const [login, setLoginAlert] = useState(false)

    const navigation = useNavigation()
    const [alert, showAlert] = useState(false)
    const [mainMenu, setMenu] = useState([])
    useEffect(() => {
        setMenu([{
            title: strings.general,
            list: [
                { id: 'profile', title: strings.profile, img: Profile, link: 'UserInfo' },
                { id: 'favourit', title: strings.favouritesList, img: heart, link: 'UserFavourits' },
                { id: 'orders', title: strings.previousOrders, img: Recent, link: 'PreviousOrders' },
                { id: 'address', title: strings.myAddresses, img: location, link: 'UserLocation' },
                { id: 'payments', title: strings.paymentMethods, img: PaymentCardsSVG, link: 'Payments' },

            ]
        },
        {
            title: strings.settings,
            list: user?.uid ? [
                { id: 'country', title: strings.country, img: country === 'sa' ? SAFlag : AEFlag, link: 'Country' },
                { id: 'language', title: strings.languages, img: Globe, link: 'Languages' },
                { id: 'chat', title: strings.getInTouch, img: Chat, link: 'Chat' },
                { id: 'privacy', title: strings.privacy, img: SheildSVG, link: 'Privacy' },
                { id: user?.uid ? 'logout' : 'login', title: user?.uid ? strings.logOut : strings.login, img: Logout, link: '' },
            ]
                : [
                    { id: 'country', title: strings.country, img: country === 'sa' ? SAFlag : AEFlag, link: 'Country' },
                    { id: 'language', title: strings.languages, img: Globe, link: 'Languages' },
                    { id: 'chat', title: strings.getInTouch, img: Chat, link: 'Chat' },
                    { id: user?.uid ? 'logout' : 'login', title: user?.uid ? strings.logOut : strings.login, img: Logout, link: '' },
                ]
        }
        ])
    }, [user?.uid, country])
    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: backgroundColor }}>
            {
                alert && <Alert
                    msg={strings.logoutMsg}
                    title={strings.logoutTitle}
                    confirmText={strings.logOut}
                    onCancel={() => showAlert(false)}
                    onConfirm={async () => {
                        try {
                            logout()
                        } catch (error) {
                            console.log("error", error);
                        }
                        showAlert(false)
                        navigation.navigate('Login')
                    }}
                />
            }
            {
                login && <Alert title={strings.alert}
                    msg={strings.loginMsg}
                    onConfirm={() => {
                        setLoginAlert(false);
                        navigation.navigate('Login')
                    }}
                    confirmText={strings.login}
                    onCancel={() => setLoginAlert(false)} />
            }
            <ScrollView showsHorizontalScrollIndicator={false} contentContainerStyle={{ paddingBottom: 100 }}>
                {
                    mainMenu.map((rec) => {
                        return (
                            <View key={rec.title}>
                                <View style={{ backgroundColor: backgroundColor, paddingVertical: 16, paddingHorizontal: 16, width: '100%' }}>
                                    <Text style={{ textAlign: 'left', fontSize: 19, fontFamily: 'semiBold' }}>{rec.title}</Text>
                                </View>
                                {
                                    rec.list.map(item => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    switch (item.id) {
                                                        case 'logout':
                                                            return showAlert(true)
                                                        case 'login':
                                                            return navigation.navigate('Login')
                                                        case 'profile':
                                                            if (user && user?.uid) {
                                                                return navigation.navigate(item.link, { user })
                                                            } else {
                                                                return setLoginAlert(true)
                                                            }
                                                        case 'address':
                                                            if (user && user?.uid) {
                                                                return navigation.navigate(item.link, { user })
                                                            } else {
                                                                return setLoginAlert(true)
                                                            }

                                                        case 'chat':
                                                            return Linking.openURL(`whatsapp://send?phone=${'+971509515301'}&text=${'Get in touch'}`);

                                                        // if (Platform.OS === 'web') {
                                                        //     if (user) {
                                                        //         window.Tawk_API.setAttributes({
                                                        //             'name': user?.name,
                                                        //             'email': user?.email,
                                                        //         });
                                                        //     }
                                                        //     window.Tawk_API.toggle()
                                                        //     break;

                                                        // }
                                                        default:
                                                            return navigation.navigate(item.link, { user })
                                                    }
                                                }}
                                                key={item.title}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    paddingVertical: 22,
                                                    backgroundColor: mainColor,
                                                    paddingHorizontal: 16,
                                                    marginBottom: 2
                                                }}>
                                                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Image
                                                        svg
                                                        uri={item.img}
                                                        width={20}
                                                        height={20}
                                                    />
                                                    <Text style={{ fontSize: 14, fontFamily: 'semiBold', paddingHorizontal: 12 }}>{item.title}</Text>
                                                </View>
                                                <Image
                                                    svg
                                                    uri={locale === 'en' ? ArrowRight : BackSVG}
                                                    width={15}
                                                    height={15}
                                                />
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </View>
                        )
                    })
                }
            </ScrollView>
        </SafeAreaView>
    );
};

const mapStateToProps = ({ user }) => {
    return { user }
}

export default connect(mapStateToProps, {})(UserProfile);